.contact-container {
  padding: 100px 30px;
}

.contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-title h3:after {
  left: 50%;
  transform: translateX(-50%);
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
}

.form_status {
  width: 100%;
  margin: 0 0 20px;
}

.form_field {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
  width: 49%;
}

.form_field.subject,
.form_field.message {
  width: 100%;
}

.form_field label {
  font-family: 'lato';
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 10px;
}

.form_field input,
.form_field textarea {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 2px;
  width: 100%;
  margin: 0;
  font-family: 'lato';
  font-weight: 300;
  font-size: 14px;
}

.form_field.submit {
  width: 49%;
}

.form_field #submit {
  border: 1px solid #b50d0d;
  background: #b50d0d;
  color: #fff;
  font-size: 14px;
  line-height: 34px;
  margin: 0;
  padding: 0 40px;
  text-transform: uppercase;
}

.form_field #submit:hover,
.form_field #submit:focus {
  border: 1px solid #b50d0d;
  background: #fff;
  color: #b50d0d;
  cursor: pointer;
}
