.promo-container {
  background: #222;
  padding: 75px 30px;
}

.promo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

.promo-title h3 {
  color: #fff;
  text-transform: capitalize;
}

.promo-title h3:after {
  left: 50%;
  transform: translateX(-50%);
}

.promo-image {
  display: block;
  max-width: 400px;
  width: 100%;
  margin: 0 0 20px;
}

.promo-message p {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
}

.links-container {
  display: flex;
  flex-wrap: wrap;
}

.links-container a {
  border: 1px solid #b50d0d;
  background: #b50d0d;
  color: #fff;
  font-size: 14px;
  flex: 1 0 calc(50% - 10px);
  line-height: 34px;
  margin: 0 5px 10px;
  padding: 0 40px;
  text-align: center;
  text-transform: uppercase;
}

.links-container a:hover,
.links-container a:focus {
  border: 1px solid #fff;
  background: #fff;
  color: #b50d0d;
}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 401px) {
  .promo-container {
    padding: 50px 20px;
  }
}
