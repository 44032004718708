.videos-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 100px 60px;
  position: relative;
}

.videos-container:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.6);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.slick-slider {
  z-index: 2;

}

.slick-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}

.slick-arrow:before {
  font-family: 'Icomoon';
  font-size: 40px;
}

.slick-prev:before {
  content: '\e902';

}

.slick-next:before {
  content: '\e901';
}

.video-slide {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-slide .slide-title h2 {
  color: #fff;
  text-align: center;
}

.video-slide iframe {
  margin: 0 auto;
  max-width: 100%;
}

@media screen and (max-width: 900px ) {
  .videos-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 100px 20px;
    position: relative;
  }

  .slick-slider {
    padding: 0 0 80px;
  }

  .slick-arrow {
    top: auto;
    bottom: 0;
    transform: translateY(0);
  }

  .slick-prev {
    left: 30%;
  }

  .slick-next {
    right: 30%;
  }
}

@media screen and (max-width: 600px ) {
  .video-slide .slide-title h2 {
    font-size: 28px;
  }

}
