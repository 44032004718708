.hero-container-wrapper {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 900px;
  position: relative;
}

.hero-container-wrapper:before {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, transparent 90%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.intro-content {
  position: relative;
  z-index: 1;
}

.intro-content .callout-title h1{
  color: #fff;
  text-align: center;
  font-size: 85px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}

.intro-content .callout-message p {
  color: #fff;
  text-align: center;
}

.intro-content .promo-video iframe {
  display: block;
  margin: 0 auto;
}

.newsletter-signup > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 40px;
}

.newsletter-signup > div > div {
  display: none;
}

.newsletter-signup input {
  border: none;
  background: #fff;
  color: #222;
  font-size: 16px;
  line-height: 36px;
  margin: 0 5px 5px 0;
  padding: 0 10px;

}
.newsletter-signup button {
  border: 1px solid #b50d0d;
  background: #b50d0d;
  color: #fff;
  font-size: 14px;
  line-height: 34px;
  margin: 0 0 5px;
  padding: 0 40px;
  text-transform: uppercase;
}

.newsletter-signup button:hover,
.newsletter-signup button:focus {
  border: 1px solid #fff;
  background: #fff;
  color: #b50d0d;
}

/* Mobile Styles */
/* ================ */

@media screen and (max-width: 900px) {
  .hero-container-wrapper {
    height: 800px;
    min-height: 800px;

  }

  .intro-content .callout-title h1{
    font-size: 60px;
  }
}

@media screen and (max-width: 900px) {
  .intro-content .callout-title h1{
    font-size: 40px
  }

  .promo-video iframe {
    width: 90vw;
  }
}

@media screen and (max-width: 900px) {

}
