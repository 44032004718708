html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'lato';
}

* {
  box-sizing: border-box;
}

input, textarea, select {
  -webkit-appearance: none;
  -webkit-border-radius:0;
  appearance: none;
  border-radius: 0;
  font-family: 'lato'
}

#root,
.App {
  height: 100%;
  width: 100%;
  position: relative;
}

iframe {
  width: 640px;
  height: 360px;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0 0 30px;
}

h1 {
  color: #5a5a5a;
  font-size: 42px;
  font-weight: 300;
  line-height: 44px;
}

h2 {
  color: #5a5a5a;
  font-size: 38px;
  font-weight: 300;
  line-height: 40px;
}

h3 {
  color: #5a5a5a;
  font-size: 34px;
  font-weight: 500;
  line-height: 36px;
  padding: 0 0 20px;
  position: relative;
  text-transform: uppercase;
}

h3:after {
  content: '';
  background: #b50d0d;
  height: 3px;
  width: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
}

h4 {
  color: #5a5a5a;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;
}

h5 {
  color: #5a5a5a;
  font-size: 26px;
  font-weight: 300;
  line-height: 28px;
}

h6 {
  color: #5a5a5a;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px;
}

p {
  color: #5a5a5a;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

a {
  font-weight: 500;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #b50d0d;
}

button:hover,
button:focus {
  cursor: pointer;
}
