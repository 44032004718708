footer {
  background: #222222;
  display: flex;
  height: 70px;
  width: 100%;
  padding: 0 30px;
  position: relative;
}

.footer-content {
  display: flex;
  align-items: center;
}

footer p {
  color: #fff;
  font-size: 12px;
  margin: 0;
}

footer a {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

footer a:hover,
footer a:focus {
  color: #fff;
  text-decoration: underline;
}
