.about-container {
  padding: 75px 30px;
}

.about-wrapper {
  display: flex;
  align-items: center;
  max-width: 1180px;
  margin: 0 auto;
}

.about-wrapper .logo {
  margin: 0 50px 0 0;
}

.about-wrapper .logo img {
  display: block;
  max-width: 300px;
}

@media screen and (max-width: 800px) {
  .about-wrapper {
    flex-direction: column;
    justify-content: center;
  }

  .about-wrapper .logo {
    margin: 0 0 50px;
  }
}

@media screen and (max-width: 401px) {
  .about-container {
    padding: 50px 20px;
  }
}
