header {
  background: transparent;
  padding: 14px 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: background .3s;
}

header.dark {
  background: #222;
  transition: background .3s;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1180px;
  margin: 0 auto;
}

.header-wrapper .logo-text {
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;
}

.header-wrapper .nav-main {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header-wrapper .nav-main li {
  margin-left: 40px;
  padding: 10px 0;
}

.header-wrapper .nav-main li a {
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
}

.header-wrapper .nav-main li a:hover,
.header-wrapper .nav-main li a:focus {
  color: #b50d0d;
}

.header-wrapper .menu {
  background: none;
  border: none;
  display: none;
  font-size: 0;
}

.header-wrapper .menu:before {
  content: '\e900';
  font-family: 'Icomoon';
  color: white;
  font-size: 30px;
}

header.menu-active .header-wrapper .menu:before {
  content: '\e907';
}

/* Mobile Styles */
/* ================ */

@media screen and (max-width: 900px) {
  header {
    background: #222;
  }

  .header-wrapper .menu {
    display: block;
  }

  .header-wrapper .nav-main {
    background: #222;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 60px;
    left: 0;
    text-align: center;
    transform: translateX(-100%);
    transition: transform .3s;
  }

  header.menu-active .header-wrapper .nav-main {
    transform: translateX(0%);
    transition: transform .3s;
  }

  .header-wrapper .nav-main li a {
    font-size: 20px;
  }
}

@media screen and (max-width: 401px) {
  header {
    padding: 14px 20px;
  }

  .header-wrapper .logo-text {
    font-size: 20px;
  }
}
