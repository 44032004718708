.artist-container {
  background: #4C4C4C;
}

.artist-outer-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  padding: 0 30px;
  position: relative;
}

.artist-outer-wrapper:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.7);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.artist-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1180px;
  margin: 0 auto;
  padding: 200px 0;
  width: 100%;
  position: relative;
  z-index: 2;
}

.artist-logo {
  margin: 0 0 30px;
}

.artist-logo[src*="lex"] {
  max-width: 150px;
}

.artist-logo[src*="jack"] {
  max-width: 320px;
}

.artist-message {
  margin: 0 0 30px;
}

.artist-message p {
  color: #fff;
  text-align: center;
}

.artist-promo-title h2 {
  color: #fff;
  margin: 0 0 50px;
}

.artist-wrapper iframe {
  display: block;
  margin: 0 auto 50px;
  max-width: 100%;
}

.artist-wrapper .social-links {
  margin: 0 0 50px;
}

.artist-wrapper .social-links a {
  color: #fff;
  font-size: 0;
  margin: 0 15px;
}

.artist-wrapper .social-links a[href*="facebook"]:before {
  content: '\e906';
  font-family: 'Icomoon';
  color: white;
  font-size: 30px;
}

.artist-wrapper .social-links a[href*="instagram"]:before {
  content: '\e905';
  font-family: 'Icomoon';
  color: white;
  font-size: 30px;
}

.artist-wrapper .social-links a[href*="twitter"]:before {
  content: '\e904';
  font-family: 'Icomoon';
  color: white;
  font-size: 30px;
}

.artist-wrapper .social-links a[href*="soundcloud"]:before {
  content: '\e903';
  font-family: 'Icomoon';
  color: white;
  font-size: 30px;
}

.artist-wrapper .social-links a:hover,
.artist-wrapper .social-links a:focus {
  color: #b50d0d;
}

.artist-wrapper .social-links a:hover::before,
.artist-wrapper .social-links a:focus::before {
  color: #b50d0d;
}

.image-group {
  display: flex;
  justify-content: space-between;
}

.image-group img {
  width: 260px;
  height: 260px;
  margin: 5px;
}

@media screen and (max-width: 1160px) {
  .image-group {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 900px ) {
  .artist-outer-wrapper {
    background-attachment: none;
  }

  .artist-wrapper {
    padding: 100px 0;
  }
}

@media screen and (max-width: 600px) {
  .image-group {
    display: block;
  }

  .image-group img {
    width: 100%;
    height: auto;
    margin: 0 0 10px;
  }
}

@media screen and (max-width: 401px) {

  .artist-outer-wrapper {
    padding: 0 20px;
  }
}
